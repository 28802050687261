import React, {useEffect, useState} from "react";
import { shallowEqual, useSelector } from "react-redux";
import {  useDispatch } from "react-redux";
import * as actions from "../_redux/dashboard/Actions";

import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import config from "../../../../config";
import * as auth_actions from "../../Auth/_redux/authRedux";
import { refresh_token } from "../../Auth/_redux/authCrud";
import {Container, Row, Col} from "react-bootstrap";

export function Dashboard() {

    const dispatch = useDispatch();

    const [tab, setTab] = useState('Location');

    const tabs = [
        {
            "name": "Location", "key": 'location'
        },
        {
            "name": "Dangers", "key": 'danger'
        }
    ]

    const { org, dashboard_data } = useSelector(
        (state) => ({
            org: state.auth.organization,
            dashboard_data: state.dashboard.dashboard_data
        }),
        shallowEqual
    );


    useEffect(() => {
        init_functions();
        
    }, [dispatch])

    async function getInitialData() {
        init_functions();    
        // let last_refresh = localStorage.getItem(config.last_refresh);
        // if (last_refresh) {
        //     var now = new Date()
        //     var when_last = new Date(JSON.parse(last_refresh));
        //     var hours = Math.abs((now - when_last) / 36e5);
        //     if (hours > 22) {
        //         //call refresh!
        //         refresh();
        //     } else {
        //         init_functions();
        //     }
        // } else {
        //     //call refresh!
        //     refresh();
        // }
           
       
    }

    function refresh() {
        refresh_token().then((response) => {
            var data = response.data;
            if (data.status === 'Success') {
                dispatch(auth_actions.actions.fulfillToken(data.data.access_token));
                localStorage.setItem(config.last_refresh, JSON.stringify(new Date()));
                init_functions();
          }
        }).catch((error) => {
            console.log("Refresh token error", error)
        });
    }

    function init_functions() {
        if (org) {
            dispatch(actions.fetchAllData('dashboard_data', org._id ));
        }
    }



    return (<>



        {/* <Container>
            <Row>
                <Col>1 of 2</Col>
                <Col>2 of 2</Col>
            </Row>
            <Row>
                <Col>1 of 3</Col>
                <Col>2 of 3</Col>
                <Col>3 of 3</Col>
            </Row>
        </Container> */}
        

        <div style = {{
            border: '1px solid #E0E0E0',
            // height: '100%'
        }}>


            <Row>
                <Col xs = {12} md = {4} sm = {12} lg = {4} 
                 style = {{
                    borderRight: '1px solid #E0E0E0', 
                }}
                >
                
                <div>

                    <div style = {{flex: 1, padding:30}}>
                    <p style = {{
                        marginTop: 50,
                        color: '#434040', fontSize: 14, fontWeight: 600
                    }}>
                        <SVG style = {{width: 14, height: 14, marginRight: 5}} src={toAbsoluteUrl('/media/nav/sign.svg')}/> {`  `}
                        Incidents resolution stats
                    </p>


                    <h1 style = {{
                        marginTop: 50,
                        color: '#434040', fontSize: 40, fontWeight: 600
                    }}>
                        {dashboard_data ? dashboard_data.incident_percentage_text === 'NaN%' ? '0%' : dashboard_data.incident_percentage_text : '...'}
                    </h1>

                    <p style = {{
                        marginTop: 20,
                        color: '#787474', fontSize: 14
                    }}>
                        Your incidents completion rate
                    </p>

                    <h1 style = {{
                        marginTop: 50,
                        color: '#434040', fontSize: 40, fontWeight: 600
                    }}>
                        {dashboard_data ? dashboard_data.average_response_percentage_text : '...'}
                    </h1>

                    <p style = {{
                        marginTop: 20,
                        color: '#787474', fontSize: 14
                    }}>
                        Your average response time to incidents
                    </p>

                    </div>

                    </div>

                </Col>
                <Col xs = {12} md = {4} sm = {12} lg = {4}>
                    <Col style = {{
                        borderBottom: '1px solid #E0E0E0'
                    }}>
                        <div style = {{padding: 30, marginBottom: 30}}>
                            <p style = {{
                                marginTop: 50,
                                color: '#434040', fontSize: 14
                            }}>
                                TOTAL AGENTS
                            </p>

                            <div style = {{
                                flexDirection: 'row',display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginTop: 50,
                                
                            }}>
                                <h1 style = {{
                                    color: '#434040', fontSize: 28, fontWeight: 600
                                }}>
                                    {dashboard_data ? dashboard_data.total_agent : '0'}
                                </h1>
                                <SVG style = {{width: 178, height: 67, marginRight: 5}} src={toAbsoluteUrl('/media/nav/analy.svg')}/> {`  `}
                            </div>

                        </div>
                    </Col>
                    <Col>
                    <div style = {{padding: 30, marginBottom: 30 }}>
                        <p style = {{
                            marginTop: 50,
                            color: '#434040', fontSize: 14
                        }}>
                        INCIDENTS COMPLETED
                        </p>

                        <div style = {{
                            flexDirection: 'row',display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: 50,
                            
                        }}>
                            <h1 style = {{
                                color: '#434040', fontSize: 28, fontWeight: 600
                            }}>
                                {dashboard_data ? dashboard_data.completed : '0'}
                            </h1>
                            <SVG style = {{width: 178, height: 67, marginRight: 5}} src={toAbsoluteUrl('/media/nav/bar.svg')}/> {`  `}
                        </div>


                        </div>
                    </Col>
                </Col>
                <Col  xs = {12} md = {4} sm = {12} lg = {4} style = {{
                    borderLeft: '1px solid #E0E0E0'
                }}>
                    <Col style = {{
                         borderBottom: '1px solid #E0E0E0'
                    }}>
                    
                    <div style = {{padding: 30, marginBottom: 30}}>

                        <p style = {{
                            marginTop: 50,
                            color: '#434040', fontSize: 14
                        }}>
                            TOTAL INCIDENTS
                        </p>

                        <div style = {{
                            flexDirection: 'row',display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: 50,
                            
                        }}>
                            <h1 style = {{
                                color: '#434040', fontSize: 28, fontWeight: 600
                            }}>
                                {dashboard_data ? dashboard_data.total_incidents : '0'}
                            </h1>
                            <SVG style = {{width: 178, height: 67, marginRight: 5}} src={toAbsoluteUrl('/media/nav/bar.svg')}/> {`  `}
                        </div>

                    </div>

                    </Col>
                    <Col>
                    <div style = {{padding: 30, marginBottom: 30 }}>
                        <p style = {{
                            marginTop: 50,
                            color: '#434040', fontSize: 14
                        }}>
                        PENDING INCIDENTS
                        </p>

                        <div style = {{
                            flexDirection: 'row',display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: 50,
                            
                        }}>
                            <h1 style = {{
                                color: '#434040', fontSize: 28, fontWeight: 600
                            }}>
                                {(dashboard_data && dashboard_data.aggregate && dashboard_data.aggregate.length > 0)  ? dashboard_data.aggregate[0].count : '0'}
                            </h1>
                            <SVG style = {{width: 178, height: 67, marginRight: 5}} src={toAbsoluteUrl('/media/nav/analy.svg')}/> {`  `}
                        </div>


                        </div>
                    </Col>
                </Col>
            </Row>

        </div>


</>);
}

import * as requestFromServer from "./Crud";
import {AlertSlice, callTypes} from "./Slice";

const {actions} = AlertSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      if (type === 'count_status') {
        dispatch(actions.countStatus(res.data));
      } else if (type === 'active_danger') {
        dispatch(actions.activeDanger(res.data));
      } else if (type === 'pending_danger') {
        dispatch(actions.pendingDanger(res.data));
      } else if (type === 'single') {
        dispatch(actions.dataFetched(res.data));
      } else if (type === 'agent_loc') {
        return res.data;
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchData = (id, type = 'f') => dispatch => {
  if (type === 'f') {
    return dispatch(actions.dataFetched(id));
  } else {
    return dispatch(actions.markers(id));
  }
};

export const deleteData = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteData(id)
    .then(response => {
      dispatch(actions.DataDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const sendAb = (id) => dispatch => {
  return dispatch(actions.ably(id));
};


export const addData = (type, data, id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addNewData(type, data, id)
    .then(response => {
      const { Data } = response.data;
      dispatch(actions.disableLoad());
    })
    .catch(error => {
      error.clientMessage = "Can't create Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateData = Data => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateData(Data)
    .then(() => {
      dispatch(actions.DataUpdated({ Data }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDatasStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForDatas(ids, status)
    .then(() => {
      dispatch(actions.DatasStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Datas status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDatas = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDatas(ids)
    .then(() => {
      dispatch(actions.DatasDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Datas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

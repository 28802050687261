import React from "react";
import { Route, Switch } from "react-router-dom";
import { CustomersLoadingDialog } from "./loading-dialog";
// import { CustomerEditDialog } from "./edit-dialog/dialog";
// import { CustomerDeleteDialog } from "./delete-dialog";
// import { ViewData } from "./view";
// import { CustomersDeleteDialog } from "./customers-delete-dialog/CustomersDeleteDialog";
// import { CustomersFetchDialog } from "./customers-fetch-dialog/CustomersFetchDialog";
// import { CustomersUpdateStateDialog } from "./customers-update-status-dialog/CustomersUpdateStateDialog";

import {Dashboard} from "./main";
import { CustomersUIProvider } from "./UIContext";
// import { CustomersCard } from "./CustomersCard";

export function DashboardPage({ history }) {
  const customersUIEvents = {
    // newCustomerButtonClick: () => {
    //   history.push("/e-commerce/customers/new");
    // },
    // openEditCustomerDialog: (id) => {
    //   history.push(`/incidents/users/${id}/edit`);
    // },
    // openDeleteCustomerDialog: (id) => {
    //   history.push(`/incidents/users/${id}/delete`);
    // },
    // enterData: (id) => {
    //   history.push(`/incidents/users/${id}/view`);
    // },

  }

  // console.log(history)

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />
      <Switch>
    
      <Route path="/dashboard" exact>
        {({ history, match }) => (
          <Dashboard
            show={match != null}
            onHide={() => {
              history.push("/");
            }}
          />
        )}
      </Route>


      {/* <Route path="/incidents/users/:id/edit">
        {({ history, match }) => (
          <CustomerEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/incidents");
            }}
          />
        )}
      </Route>

      <Route path="/incidents/users/:id/view" component={ViewData}>
     
      </Route>

      <Route path="/incidents/users/:id/delete">
        {({ history, match }) => (
          <CustomerDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/incidents");
            }}
          />
        )}
      </Route> */}


      </Switch>
    </CustomersUIProvider>
  );
}

import {createSlice} from "@reduxjs/toolkit";

const initialdatasState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  activeCount: 0,
  entities: null,
  dataForEdit: undefined,
  lastError: null,
  danger_data: [],
  location_data: [],
  userCount: 0,
  meta: null,
  pending: [],
  orgCount: 0,
  dashboard_data: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialdatasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getdataById
    dataFetched: (state, action) => {
      state.actionsLoading = false;
      state.dataForEdit = action.payload.dataForEdit;
      state.error = null;
    },
    // alerts: (state, action) => {
    //   state.listLoading = false;
    //   state.entities = action.payload;
    //   state.error = null;
    // },
    users: (state, action) => {
      state.listLoading = false;
      state.entities = action.payload;
      state.error = null;
    },
    pendingDa: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.pending = action.payload;
      state.error = null;
    },
    datasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    accounts: (state, action) => {
      state.listLoading = false;
      state.entities = action.payload;
      state.error = null;
    },
    accountsMeta: (state, action) => {
      state.listLoading = false;
      state.meta = action.payload;
      state.totalCount = action.payload.total_items;
      state.error = null;
    },
    dashboardData: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dashboard_data = action.payload;
    },
    activeIncidentCount: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.activeCount = action.payload;
    },
    userCountAction: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.userCount = action.payload;
    },
    orgCountAction: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.orgCount = action.payload;
    },
    // createdata
    dataCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.data);
    },
    // updatedata
    dataUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.data.id) {
          return action.payload.data;
        }
        return entity;
      });
    },
    // deletedata
    dataDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    dangerAnalytics: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.listLoading = false;
      state.danger_data = action.payload
    }, 
    locationAnalytics: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.listLoading = false;
      state.location_data = action.payload
    }, 
  // deletedatas
    datasDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // datasUpdateState
    datasStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});

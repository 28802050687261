import React, {useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import Loader from "../../../../../_metronic/layout/components/loader";
import TimeAgo from 'react-timeago'
import {  useDispatch } from "react-redux";
import * as actions from "../../_redux/incidents/Actions";
import { Modal } from "react-bootstrap";
import { useParams } from 'react-router-dom';

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};
export default function Lists(props) {
    const dispatch = useDispatch();
    const { id } = useParams();

 
   



  
    return (<>

            <Modal.Body className="overlay overlay-block cursor-default">
              {props.loading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
      

                <div style = {{padding: 0}}>
                {props.loading ? 
                <Loader data = {[1,2,3]} type = {"info"}/> 
                :
                !props.loading && (!props.data || props.data.length === 0) ?
                <div>
                <div className="d-flex flex-center text-center text-muted min-h-150px">
                    All caught up!
                    <br />
                    No agent found.
                </div>
                <div className = {`text-center text-danger font-weight-bold`}>
                        <a
                        onClick = {() => props.action ? props.action() : console.log('hello')}
                    >{props.actionText ? props.actionText : 'Check Again'}</a></div>
                </div>
                :
                <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="scroll pr-7 mr-n7 mt-5"
                    style={{ maxHeight: "300px", position: "relative" }}
                    >
                    
                    {props.data.map((ele, i) => {
                        return (
                            <div 
                                // onClick={() => {
                                //     props.history.push(`/incidents/view/${ele._id}`); 
                                //     dispatch(actions.fetchData(ele))
                                // }}
                                key = {i} 
                                style = {{
                                    justifyContent: 'space-between',
                                }}
                                className="d-flex align-items-center mb-5"
                            >
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-40 symbol-light-primary mr-3">
                                    <span className="symbol-label" 
                                    style = {{borderRadius: 40}}
                                    >
                                        <SVG
                                        src={toAbsoluteUrl(
                                            "/media/svg/avatars/050-girl-26.svg"
                                        )}
                                        className="svg-icon-lg svg-icon-primary"
                                        style = {{
                                            width: 30, 
                                            heght: 30,
                                        }}
                                        ></SVG>
                                    </span>
                                    </div>
                                    <div className="d-flex flex-column font-weight-bold">
                                    <a
                                        className="text-dark font-weight-bold mb-1 font-size-sm"
                                        style = {{ 
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {`${ele.user.first_name} ${ele.user.last_name}`}
                                    </a>
                                    <span className="label label-md label-inline font-weight-bold" style = {{
                                        backgroundColor: '#B6D9F0'
                                    }}>
                                        {/* 21 KM AWAY */}
                                        {ele.user.email}
                                    </span>
                                </div>

                               
                                </div>
                                <div className="d-flex flex-row" >
                                    <a 
                                    onClick={() => {
                                        var body = {};
                                        body['agent_id'] = ele.user.id;
                                        dispatch(actions.addData('assign', body, id)).then((res) => {
                                            props.onHide()
                                            dispatch(actions.fetchAllData('single', id ))
                                          }).catch(() => { 
                                            alert("An error occurred while assigning this agent");return;
                                        })
                                    }}
                                    
                                    // href="#" 
                                    class="btn btn-outline-primary btn-xs">
                                        Assign
                                    </a>
                                </div>
                            </div>
                        )
                    })}
                

                    </PerfectScrollbar>
                    }
                    </div>
                

                    </Modal.Body>
                <Modal.Footer>
                {/* <a onClick={() => props.onHide()} class="btn btn-text-dark font-weight-bold mr-2">Cancel Operation</a> */}
                {/* <> </> */}
                {/* <button
                    form='update-form'
                    type="submit"
                    disabled = {actionsLoading}
                    // onClick={() => saveCustomer()}
                    className="btn btn-primary btn-elevate btn-square"
                >
                Add agent
              </button> */}
            </Modal.Footer>


         


</>);
}

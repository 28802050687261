import React, {useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import Loader from "../../../../_metronic/layout/components/loader";
import TimeAgo from 'react-timeago'
import {  useDispatch } from "react-redux";
import * as actions from "../_redux/incidents/Actions";

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};
export default function Lists(props) {
    const dispatch = useDispatch();

 
    const [data, set_data] = useState([]);
   
    const { active_danger, loading, pending_danger } = useSelector(
      (state) => ({
        active_danger: state.alert.active_danger,
        pending_danger: state.alert.pending_danger,
        loading: state.alert.listLoading
      }),
      shallowEqual
    );

    useEffect(() => {
        if (props.type === 'pending') {
            if (pending_danger && pending_danger.data.length > 0) {
                set_data(pending_danger.data)
            } else {
                set_data([]);
            }
        } else {
            if (active_danger && active_danger.data.length > 0) {
                set_data(active_danger.data)
            } else {
                set_data([]);
            }
        }
    }, [active_danger, pending_danger, props.type])




  
    return (<>

      

                <div>
                {loading ? 
                <Loader data = {[1,2,3]} type = {"info"}/> 
                :
                !loading && data.length === 0 ?
                <div>
                <div className="d-flex flex-center text-center text-muted min-h-150px">
                    All caught up!
                    <br />
                    No {props.type} request.
                </div>
                <div className = {`text-center text-danger font-weight-bold`}>
                        <a
                        onClick = {() => props.action ? props.action() : console.log('hello')}
                    >{props.actionText ? props.actionText : 'Try Again'}</a></div>
                </div>
                :
                <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="scroll pr-7 mr-n7 mt-5"
                    // style={{ maxHeight: "300px", position: "relative" }}
                    >
                    
                    {data.map((ele, i) => {
                        return (
                            <div 
                            onClick={() => {
                                props.history.push(`/incidents/view/${ele._id}`); 
                                dispatch(actions.fetchData(ele))
                            }}
                            key = {i} className="d-flex align-items-center mb-10">
                                <div className="symbol symbol-40 symbol-light-primary mr-5" style = {{width: '10%'}}>
                                <span className="symbol-label" 
                                style = {{borderRadius: 40}}
                                >
                                    <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/avatars/050-girl-26.svg"
                                    )}
                                    className="svg-icon-lg svg-icon-primary"
                                    style = {{
                                        width: 30, 
                                        heght: 30,
                                    }}
                                    ></SVG>
                                </span>
                                </div>
                                <div className="d-flex flex-column font-weight-bold" style = {{width: '50%'}}>
                                <a
                                    // onClick={() => props.history.push(`/incidents/view/${ele._id}`)}
                                    className="text-dark text-hover-primary mb-1 font-size-sm"
                                    style = {{ 
                                        overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}
                                >
                                    {`${ele.user && ele.user.first_name ? ele.user.first_name : 'Firstname'} ${ele.user && ele.user.last_name ? ele.user.last_name : 'Lastname'}`}
                                </a>
                                <span className="text-muted font-size-xs">
                                    <TimeAgo 
                                        date={ele.created_at} 
                                    />
                                </span>
                                </div>
                                <div className="d-flex flex-row" style = {{width: '40%'}} >
                                <span class="label label-md label-inline font-weight-bold mr-2" style = {{backgroundColor: '#FFE2E1', 
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'}}>{ele.category.name}</span>
                                <SVG style = {{width: 20, height: 20}} src={toAbsoluteUrl('/media/nav/arrow.svg')}/>
                                </div>
                            </div>
                        )
                    })}

                    </PerfectScrollbar>
                    }
                    </div>
                

             


         


</>);
}

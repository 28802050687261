import axios from "axios";
import config from "../../../../../config";

export const URL = config.URL;

// CREATE =>  POST: add a new data to the server
export function addNewData(type, data, id) {
  // return axios.post(URL, { data });
  if (type === 'assign') {
    return axios.post(`${URL}dangers/${id}/assign-agent`, data);
  }
}

// READ
export function getAllDatas() {
  return axios.get(URL);
}

export function getSataById(dataId) {
  return axios.get(`${URL}/${dataId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDatas(type, queryParams) {
  if (type === 'count_status') {
    return axios.get(`${URL}partners/${queryParams}/count-status`);
  } else if (type === 'active_danger') {
    return axios.get(`${URL}partners/${queryParams}/active-danger`);
  } else if (type === 'pending_danger') {
    return axios.get(`${URL}partners/${queryParams}/pending-danger`);
  } else if (type === 'single') {
    return axios.get(`${URL}dangers/${queryParams}`);
  } else if (type === 'agent_loc') {
    return axios.get(`${URL}locations/agents/${queryParams}`);
  }
}

// UPDATE => PUT: update the data on the server
export function updateData(data) {
  return axios.put(`${URL}/${data.id}`, { data });
}

// UPDATE Status
export function updateStatusForDatas(ids, status) {
  return axios.post(`${URL}/updateStatusFordatas`, {
    ids,
    status
  });
}

// DELETE => delete the data from the server
export function deleteData(dataId) {
  return axios.delete(`${URL}/${dataId}`);
}

// DELETE datas by ids
export function deleteDatas(ids) {
  return axios.post(`${URL}/deletedatas`, { ids });
}

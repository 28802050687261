import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { toast } from 'react-toastify';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

function Registration(props) {
  const search = window.location.search;
  const phoneRef = useRef();
  const params = new URLSearchParams(search);
  const email = params.get('email');
  const primary = params.get('primary');
  const invitation_id = params.get('invitation_id');
  const token = params.get('token');

  const [phoneNumber, setPhoneNumber] = useState("");
  const [data, setData] = useState({});
  const [phone_err, setPhoneErr] = useState('');
  


  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    last_name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    gender: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
      // phone_number: Yup.number()
      // // .min(3, "Minimum 3 symbols")
      // // .max(50, "Maximum 50 symbols")
      // .required(
      //   intl.formatMessage({
      //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
      //   })
      // ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });


  const initialValues = {
    last_name: "",
    first_name: "",
    email: email,
    username: "",
    password: "",
    changepassword: "",
    acceptTerms: false,
    primary: "primary",
    // user_id: "",
    // invitation_id: "",
    // token: "",
    // country_code: "NG",
    // country_phone_code: "+234"
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      if (!values.email) {
          disableLoading();
          return toast.error(`Error: Please revalidate this link from your email`);
      }
      if (phoneNumber === "") {
          disableLoading();
          return toast.error(`Error: Please enter your phone number`);
      }
      if (phone_err) {
          disableLoading();
          return toast.error(`Error: ${phone_err}`);
      }

      let body_values = {
        token: token,
        invitation_id: invitation_id,
        user_data: {
          email: values.email,
          last_name: values.last_name,
          first_name: values.first_name,
          gender: values.gender,
          password: values.password,
          phone_number: formatPhoneNumberIntl(`+${data?.dialCode}${phoneNumber}`).replace(/\s/g, ''),
          country_phone_code: `${data?.iso2.toUpperCase()}`,
          country_code: `+${data?.dialCode}`
        } 
      } 
      // console.log(formatPhoneNumber(`+${data?.dialCode}${phoneNumber}`));
      // console.log(formatPhoneNumberIntl(`+${data?.dialCode}${phoneNumber}`).replace(/\s/g, ''));
     
      // console.log(body_values); return;
      register(body_values)
        .then(({ data }) => {
          // props.register(accessToken);
          props.history.push({
            pathname: '/auth/activate',
            state: {
              email: values.email,
              password: values.password
            }
          })
          disableLoading();
        })
        .catch((error) => {
          setSubmitting(false);
          setStatus(
            error?.response?.data?.message,
          )
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="First name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "first_name"
            )}`}
            name="first_name"
            {...formik.getFieldProps("first_name")}
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.first_name}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Last name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "last_name"
            )}`}
            name="last_name"
            {...formik.getFieldProps("last_name")}
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.last_name}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
            disabled
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}


        <div className="form-group fv-plugins-icon-container">
            <IntlTelInput
                ref = {phoneRef}
                preferredCountries={['ng', 'za', "us", "gb", 'gh']}
                inputClassName={`form-control form-control-solid h-auto py-5  ${getInputClasses(
                    "tel"
                )}`}
                style = {{
                    width: '100%'
                }}
                format = {true}
                onPhoneNumberFocus = {(isValid, number, obj) => {
                  var num = `+${obj?.dialCode}${number}`;
                  var isValidR = isValidPhoneNumber(num)
                  console.log(isValidR)
                  setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                }}
                onPhoneNumberBlur= {(isValid, number, obj) => {
                  var num = `+${obj?.dialCode}${number}`;
                  var isValidR = isValidPhoneNumber(num)
                  console.log(isValidR)
                  setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                }}
                onPhoneNumberChange = {(isValid, number, obj) => {
                    var num = `+${obj?.dialCode}${number}`;
                    var isValidR = isValidPhoneNumber(num)
                    setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                    setPhoneNumber(number)
                    setData(obj)
                }}
                containerClassName="intl-tel-input"
            />
            {phone_err ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{phone_err}</div>
                </div>
            ) : null}
        </div>


        {/* begin: Gender */}
        <div className="form-group fv-plugins-icon-container">
          <select name='gender' {...formik.getFieldProps("gender")} className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "gender"
              )}`}>
              <option>Gender</option>
              <option value='male'>Male</option>
            <option value='female'>Female</option>
          </select>
          {formik.touched.gender && formik.errors.gender ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.gender}</div>
            </div>
          ) : null}
        </div>
        {/* end: Gender */}

     

        {/* begin: phone number *
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Phone Number"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "phone_number"
            )}`}
            name="phone_number"
            {...formik.getFieldProps("phone_number")}
          />
          {formik.touched.phone_number && formik.errors.phone_number ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phone_number}</div>
            </div>
          ) : null}
        </div>
        {/* end: phone number */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link to="/terms" target="_blank" className="mr-1" rel="noopener noreferrer">
            I agree the Terms & Conditions
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.values.acceptTerms}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
